import { IconContainer } from "./VendorIcon.styles";
import RewardsIcon from "../../assets/rewards.png";

export default function VendorRewardsIcon(): React.ReactElement {
  return (
    <IconContainer>
      <img width={36} src={RewardsIcon} alt="Tao Group Rewards icon" />
    </IconContainer>
  );
}
