export const vendorColor = "#040507";
export const vendorDefaultTitle =
  "Tao Group Hospitality Pay: Pay from your phone";

export const rateExperienceTitle="How was your payment experience?";
export const rateExperienceBadHref = "https://forms.gle/n2DDL5nuL3vutkzD8";
export const rateExperienceGreatHref = "https://forms.gle/f3jXNZD1qEhi5nzi9";

export const defaultRedirectionUrl = "#";

export const applePayLabels = {
  paymentSheet: {
    totalLabel: "Tao Group Hospitality Pay"
  },
  validation: {
    displayName: "Tao Group Hospitality Pay"
  }
};
