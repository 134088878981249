import styled from "styled-components";

export const MessageContainer = styled.div`
  padding: 18px 0;

  .icon {
    height: 22px;
    width: 22px;
  }
`;
