import { LogoContainer } from "./VendorLogo.styles";
import { ReactComponent as TaoLogo } from "../../assets/logo.svg";

const VendorLogo = (): React.ReactElement => {
  return (
    <LogoContainer>
      <TaoLogo title="vendor-logo" />
    </LogoContainer>
  );
};

export default VendorLogo;
