import * as Styled from "./VendorTopBar.styles";

import React, { useCallback } from "react";

import { ArrowBack } from "@mui/icons-material";
import Button from "@component/Button/Button";
import VendorLogo from "@vendor/components/VendorLogo/VendorLogo";
import { useNavigate } from "react-router-dom";
import { usePlace } from "@context/PlaceContext";

const VendorTopBar = () => {
  const { place } = usePlace();

  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      <Styled.Wrapper className="overwrite" position="fixed">
        <Styled.Container className="overwrite">
          <Button
            className="back-btn"
            icon={<ArrowBack fontSize="large" color="inherit" />}
            onClick={handleGoBack}
            size="small"
            variant="text"
          />
          <VendorLogo />
          <Button
            className="login-btn"
            size="small"
            variant="text"
            disabled={true}
          />
        </Styled.Container>
        {place?.name && (
          <Styled.LocationName>
            <h1>{place?.name}</h1>
          </Styled.LocationName>
        )}
      </Styled.Wrapper>
    </>
  );
};

export default VendorTopBar;
