import { Params } from "@remix-run/router/utils";
import { isDev } from "@utils/constants";
import { isTruthy } from "@utils/truthy";
import { redirect } from "react-router-dom";

export const getPlaceRedirectionIfNeeded = (
  params: Params
): Response | undefined => {
  const placeRedirectionUrl = process.env.REACT_APP_PLACE_REDIRECTION_URL;
  const placeRedirectionPlaceCodeList =
    process.env.REACT_APP_PLACE_REDIRECTION_PLACECODES ?? "";

  const placeCode = params.placeCode ?? params.placePermalink;

  if (isDev) {
    console.log(
      "Checking if redirection is needed",
      placeRedirectionUrl,
      placeRedirectionPlaceCodeList,
      placeCode
    );
  }

  if (
    isTruthy(placeCode) &&
    isTruthy(placeRedirectionUrl) &&
    placeRedirectionPlaceCodeList.includes(placeCode)
  ) {
    const destination =
      placeRedirectionUrl +
      `${window.location.pathname}${window.location.search}`;

    return redirect(destination);
  } else {
    return undefined;
  }
};
